<template>
    <div>
      <div v-loading="loading" element-loading-spinner="el-icon-loading">
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/iotConfigManage' }">组态管理</el-breadcrumb-item>
            <el-breadcrumb-item>编辑项目</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-box">
          <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
            <div class="form-title">基本信息</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="form.name" maxlength="30" placeholder="请输入项目名称" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="display:flex;justify-content:space-between;align-items:center;">
              <div class="form-title">人员信息</div>
              <el-button type="primary" size="small" @click="addStaff">选择人员</el-button>
            </div>
            <el-table :data="staffList" style="width: 100%">
              <el-table-column prop="name" label="人员名称"></el-table-column>
              <!-- <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                  <span v-show="scope.row.sex==0">女</span>
                  <span v-show="scope.row.sex==1">男</span>
                </template>
              </el-table-column>
              <el-table-column prop="departmentName" label="部门"></el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column> -->
              <el-table-column prop="authority" label="权限">
                <template slot-scope="scope">
                  <el-select size="small" v-model="scope.row.authority" placeholder="请选择权限">
                    <el-option
                      v-for="item in authority"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <div>
                    <span @click="dltStaff(scope.row)" style="color:red;cursor:pointer;">删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            
            <!-- 图表绑定 -->
            <bind-chart @getChartList="getChartLists" :chartLists="chartList" :resource="'edit'"></bind-chart>

            <div class="bottom-btn">
              <el-button type="primary" size="small" @click="$router.push('/iotConfigManage')">取消</el-button>
              <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            </div>
          </el-form>
        </div>
        <el-drawer
          title=""
          :with-header="false"
          :visible.sync="drawer"
          direction="rtl"
          size="900px">
          <div class="drawer-title">选择人员</div>
          <div class="drawer-content" v-if="drawer">
            <div class="table-tree">
              <div class="tree">
                <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
                <el-tree
                  :data="treeData"
                  @node-click="treeRowClick"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  default-expand-all
                  ref="tree">
                  <span class="custom-tree-node" slot-scope="{ node }">
                    <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                  </span>
                </el-tree>
              </div>
              
              <div class="table">
                <el-table :data="tableData" style="width: 100%" height="700">
                  <el-table-column width="40">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="姓名"></el-table-column>
                  <el-table-column prop="sex" label="性别">
                    <template slot-scope="scope">
                      <span v-if="scope.row.sex == 1">男</span>
                      <span v-else>女</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="departmentName" label="部门"></el-table-column>
                  <el-table-column prop="name" label="岗位">
                    <template slot-scope="scope">
                      <div
                        v-for="item in scope.row.positionInfoList"
                        :key="item.positionId"
                      >{{item.positionName}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="phone" label="电话"></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
            <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
          </div>
        </el-drawer>
      </div>
    </div>
  </template>
  
  <script>
  import layTime from '@/components/layTime'
  import bindChart from './projectComponent/bindChart'
  export default {
    components: {
      layTime,
      bindChart
    },
    data() {
      return {
        loading: false,
        form: {},
        rules: {
          name: [{ required: true, message: "必填项", trigger: "blur" }],
        },
        treeData: [],
        tableData: [],
        defaultProps: {
          children: 'childTree',
          label: 'name'
        },
        drawer: false,
        departmentId: 0,
        staffList: [], // 通知人员列表
        staffListID: [],
        checkedStaffList: [],
        authority: [
          { code: 'read', name: '只读' },
          { code: 'operate', name: '允许操作' },
        ],
        configProjectId: null,
        chartList: []
      };
    },
    methods: {
      // 获取已选择图表列表 
      getChartLists(e) {
        this.chartList = e
      },
      // 选择通知人员
      addStaff() {
        this.loadTableData(this.treeData[0].id)
        this.staffListID = []
        this.staffList.forEach(item => {
          this.staffListID.push(item.id)
        })
        this.checkedStaffList = [].concat(this.staffList)
        this.drawer = true
      },
      // 获取部门结构树
      loadTreeData() {
        this.$ajax.post('departmentResponsibleChild').then(res => {
          this.treeData = res.data
          this.departmentId = res.data[0].id
        })
      },
      // 获取部门人员
      loadTableData(id) {
        this.$ajax.post('queryFilter', {
          departmentId: id ? id : 0
        }).then(res => {
          res.data.forEach(item => {
            item.authority = 'read'
            let inIs = this.staffListID.find(function(obj) {
              return obj == item.id
            })
            if (!inIs) {
              item.check = false
            }else {
              item.check = true
            }
          })
          this.tableData = res.data
        })
      },
      // 选择部门结构
      treeRowClick(data) {
        this.loadTableData(data.id)
      },
      // 勾选巡检员
      checked(row) {
        if (!this.staffListID.includes(row.id)) {
          this.checkedStaffList.push(row)
          this.staffListID.push(row.id)
        } else {
          this.checkedStaffList.forEach((item, index) => {
            if (item.id == row.id) {
              this.checkedStaffList.splice(index, 1)
              this.$delete(this.staffListID, index)
            }
          })
        }
      },
      // 选择通知人员点击确定
      drawerOK() {
        this.drawer = false
        this.staffList = [].concat(this.checkedStaffList)
        this.staffListID = [].concat(this.staffListID)
      },
      // 删除人员信息
      dltStaff(row){
        this.staffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.staffList.splice(index, 1)
          }
        })
        this.checkedStaffList.forEach((v,k) => {
          if (v.id == row.id) {
            this.checkedStaffList.splice(k, 1)
          }
        })
      },
      // 提交数据
      onSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let form = {
              name: this.form.name,
              staffInfos: [],
              bindGraphs: [],
              projectId: this.configProjectId
            }
            if(this.staffList.length==0) {
              return this.$message.error('请选择人员')
            }
            this.staffList.forEach(v=>{
              form.staffInfos.push({
                staffId: v.id,
                authority: v.authority,
              })
            })
            if(this.chartList&&this.chartList.length>0){
              this.chartList.forEach(v=> {
                form.bindGraphs.push({
                  itemId: v.itemId,
                  graphId: v.graphId 
                })
              })
            }
            this.loading = true;
            this.$ajax.post("configProjectEdit", form).then((res) => {
              this.loading = false;
              this.$message.success("成功");
              this.$router.push("/iotConfigManage");
            }).catch(err=>{
              this.loading = false;
            })
          }
        });
      },
      // 获取组态项目详情
      loadProjectDetail() {
        this.loading = true
        this.$ajax.post("configProjectDetail", {
          projectId: this.configProjectId
        }).then((res) => {
          res.data.staffInfos.forEach((v)=>{
            this.staffList.push({
              id: v.staffId,
              name: v.staffName,
              authority: v.authority
            })
          })
          res.data.bindGraphInfos&&res.data.bindGraphInfos.forEach(item=>{
            let inIs = this.chartList.find(function(obj) {
              return obj.itemId == item.itemId
            })
            if (!inIs) {
              item.check = false
            }else {
              item.check = true
            }
          })
          this.chartList = res.data.bindGraphInfos
          this.form = res.data
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },
    },
    mounted() {
      if (this.$route.params.row == null) {
        this.$router.push("/iotConfigManage");
      } else {
        this.configProjectId = this.$route.params.row
        this.loadTreeData()
        this.loadProjectDetail()
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading {
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .el-input {
    width: 400px;
  }
  .table-tree {
    display: flex;
    height: ~'calc(100% - 110px)';
    margin-top: 20px;
    .tree {
      width: 220px;
      border-right: 4px solid #f6f7fb;
      margin-right: 20px;
    }
    .table {
      flex: 1;
    }
    .custom-tree-node {
      font-size: 14px;
    }
  }
  .staffBox {
    cursor: pointer;
    width: 390px;
    background: #fff;
    margin-right: 20px;
    height: 40px;
    border-radius: 4px;
    padding: 0 4px;
    border: 1px solid #DCDFE6;
  }
  /deep/.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #409eff;
    color: white;
  }
  </style>